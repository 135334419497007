import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { IOpenSnackBarConfig } from 'core/interfaces/snackbar-custom';
import { AlertComponent } from 'shared/components/alert/alert.component';
import { doLogout } from 'store/actions/auth.actions';
import { cleanMembers } from 'store/actions/member.actions';
import { cleanPlayers } from 'store/actions/player.actions';
import { clearTeamsCache } from 'store/actions/team.actions';
import { IAppState } from 'store/states/app.states';
import { LocalstorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private localStorageService: LocalstorageService,
    private apollo: Apollo,
    private store: Store<IAppState>,
    private router: Router,
  ) {}

  openSnackBar(config?: IOpenSnackBarConfig) {
    this.snackBar.openFromComponent(AlertComponent, {
      data: config,
      duration: 3000,
      panelClass: 'alert-panel-custom',
    });
  }

  openDialog<P>(component: ComponentType<any>, data?: P): void {
    this.dialog.open<typeof component>(component, {
      data,
      disableClose: true,
    });
  }

  closeAllDialogs(): void {
    this.dialog.closeAll();
  }

  handleCleanTeamsStore(): void {
    this.store.dispatch(clearTeamsCache());
  }

  handleCleanMembersStore(): void {
    this.store.dispatch(cleanMembers());
  }

  handleCleanPlayersStore(): void {
    this.store.dispatch(cleanPlayers());
  }

  doLogout(): void {
    this.localStorageService.removeSessionTokens();
    this.handleCleanTeamsStore();
    this.handleCleanMembersStore();
    this.handleCleanPlayersStore();
    this.apollo.client.resetStore();
    this.store.dispatch(doLogout());
    this.router.navigate(['/auth']);
  }
}
