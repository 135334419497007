import { IGame } from 'core/interfaces/game';

export interface IGameState {
  games: IGame[];
  loading: boolean;
}

export const initialGameState: IGameState = {
  games: [],
  loading: false,
};
