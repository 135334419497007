import { createReducer, on } from '@ngrx/store';
import { cleanPlayers, fillPlayers } from 'store/actions/player.actions';
import { initialPlayerState } from 'store/states/player.states';

export const playerReducers = createReducer(
  initialPlayerState,
  on(fillPlayers, (state, { players }) => ({ ...state, players })),
  on(cleanPlayers, (state) => ({
    ...state,
    players: [],
    loading: false,
  })),
);
