import {
  merge,
  pick,
} from 'lodash-es';

import {
  Action,
  ActionReducer,
} from '@ngrx/store';

const setSavedState = (state: any, key: string): void => {
  localStorage.setItem(key, JSON.stringify(state));
};

const getSavedState = (key: string): unknown => {
  try {
    return JSON.parse(localStorage.getItem(key) ?? '{}');
  } catch (ex) {
    return {};
  }
};

const stateKeys = [
  'auth.accessToken',
  'auth.refreshToken',
  'auth.user',
  'team.currentTeam',
  'team.currentTeamSeason',
];
const localStorageKey = 'store';

export const storageMetaReducers = <S, A extends Action = Action>(
  reducer: ActionReducer<S, A>,
) => {
  let stored = false;
  return (state: S, action: A): S => {
    const nextState = reducer(state, action);
    if (!stored) {
      stored = true;
      const savedState = getSavedState(localStorageKey);
      return merge(nextState, savedState);
    }

    const stateToSave = pick(nextState, stateKeys);
    setSavedState(stateToSave, localStorageKey);
    return nextState;
  };
};
