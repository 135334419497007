import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'environments/environment';
import { accessTokenMiddleware } from 'core/middlewares/token.middleware';

const uri = environment.BACKEND.GRAPHQL_BOARD;
export const createApollo = (
  httpLink: HttpLink,
): ApolloClientOptions<unknown> => ({
  link: accessTokenMiddleware().concat(httpLink.create({ uri })),
  cache: new InMemoryCache(),
  defaultOptions: {
    // ! Sometimes some queries doesn't update their content correctly for some reason.
    // ! We need to check this later and evaluate if we can work in a better solution or not.
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
