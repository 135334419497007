import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToPx',
})
export class StringToPxPipe implements PipeTransform {
  transform(value: number): string {
    if (value <= 0) {
      return String(0);
    }
    return `${value}px`;
  }
}
