import { createAction, props } from '@ngrx/store';
import { IInvitation } from 'core/interfaces/invitation';
import { ITeam } from 'core/interfaces/team';
import { IUser } from 'core/interfaces/user';

export const createMember = createAction(
  '[Team] Create Member',
  props<{ team: ITeam; inviteeName: string; inviteeEmail: string }>(),
);

export const createMemberSuccess = createAction(
  '[Team] Create Member | Success',
);

export const createMemberFails = createAction('[Team] Create Member | Fail');

export const fillMembers = createAction(
  '[Team] Fill Members',
  props<{ members: IUser[] }>(),
);

export const fillInvitations = createAction(
  '[Team] Fill Invitations',
  props<{ invitations: IInvitation[] }>(),
);

export const cleanMembers = createAction('[Team] Clean Members');
