import { ISeason } from 'core/interfaces/season';
import { IUploadedSeason } from 'core/interfaces/uploaded-season';

export interface ISeasonState {
  seasons: ISeason[];
  currentSeason: ISeason | null;
  loading: boolean;
  uploadedSeason: IUploadedSeason | null;
}

export const initialSeasonState: ISeasonState = {
  seasons: [],
  currentSeason: null,
  loading: false,
  uploadedSeason: null,
};
