import { ICreateGameForm } from 'core/interfaces/form-definitions/create-game-form';
import { ICreateMember } from 'core/interfaces/form-definitions/create-member';
import { ICreateSeasonForm } from 'core/interfaces/form-definitions/create-season-form';
import { ICreateTeamAdmin } from 'core/interfaces/form-definitions/create-team-admin';
import { IFilterAnalyticsForm } from 'core/interfaces/form-definitions/filter-analytics-form';
import { IUploadGameForm } from 'core/interfaces/form-definitions/upload-game-form';
import { IMenuOptions } from 'core/interfaces/menu-options';
import { TFormDrawerSelection } from 'core/types/form-selection.types';

export interface IBoardState {
  title: string | null;
  isDrawerOpen: boolean;
  drawerForm: TFormDrawerSelection;
  drawerFormResults: {
    teamAdmin: ICreateTeamAdmin | null;
    member: ICreateMember | null;
    uploadGame: IUploadGameForm | null;
    createGame: ICreateGameForm | null;
    filterAnalytics: IFilterAnalyticsForm | null;
    createSeason: ICreateSeasonForm | null;
    createTeam: null;
  };
  menuOptions: IMenuOptions[];
}

export const initialBoardState: IBoardState = {
  title: null,
  isDrawerOpen: false,
  drawerForm: 'member',
  drawerFormResults: {
    teamAdmin: null,
    member: null,
    uploadGame: null,
    createGame: null,
    filterAnalytics: null,
    createSeason: null,
    createTeam: null,
  },
  menuOptions: [],
};
