import { createAction, props } from '@ngrx/store';
import { EGamesAnalyticsType } from 'core/enums/games-file-types.enums';
import { IAnalyticsBIP } from 'core/interfaces/analytics-bip';
import { IAnalyticsOEM } from 'core/interfaces/analytics-oem';
import { IAnalyticsRISP } from 'core/interfaces/analytics-risp';
import { ITeam } from 'core/interfaces/team';

export const getAnalyticsOEM = createAction(
  '[Analytics] OEM',
  props<{ teamUUID: string; seasonUUID: string }>(),
);

export const getAnalyticsOEMSuccess = createAction(
  '[Analytics] OEM | Success',
  props<{ analyticsOEM: IAnalyticsOEM | null }>(),
);

export const getAnalyticsOEMFails = createAction(
  '[Analytics] OEM | Fails',
  props<{ errors: string[] }>(),
);

export const getAnalyticsRISP = createAction(
  '[Analytics] RISP',
  props<{ teamUUID: string; seasonUUID: string }>(),
);

export const getAnalyticsRISPSuccess = createAction(
  '[Analytics] RISP | Success',
  props<{ analyticsRISP: IAnalyticsRISP | null }>(),
);

export const getAnalyticsRISPFails = createAction(
  '[Analytics] RISP | Fails',
  props<{ errors: string[] }>(),
);

export const getAnalyticsBIP = createAction(
  '[Analytics] BIP',
  props<{ teamUUID: string; seasonUUID: string }>(),
);

export const getAnalyticsBIPSuccess = createAction(
  '[Analytics] BIP | Success',
  props<{ analyticsBIP: IAnalyticsBIP | null }>(),
);

export const getAnalyticsBIPFails = createAction(
  '[Analytics] BIP | Fails',
  props<{ errors: string[] }>(),
);

export const filterOemStatsByGames = createAction(
  '[Analytics] Filter Oem Stats By Games',
  props<{ gameUuids: string[]; team: ITeam }>(),
);

export const filterOemStatsByGamesSuccess = createAction(
  '[Analytics] Filter Oem Stats By Games | Success',
  props<{ analyticsOEM: IAnalyticsOEM | null }>(),
);

export const filterOemStatsByGamesFails = createAction(
  '[Analytics] Filter Oem Stats By Games | Fails',
);

export const filterRispStatsByGames = createAction(
  '[Analytics] Filter Risp Stats By Games',
  props<{ gameUuids: string[]; team: ITeam }>(),
);

export const filterRispStatsByGamesSuccess = createAction(
  '[Analytics] Filter Risp Stats By Games | Success',
  props<{ analyticsRISP: IAnalyticsRISP | null }>(),
);

export const filterRispStatsByGamesFails = createAction(
  '[Analytics] Filter Risp Stats By Games | Fails',
);

export const filterBipStatsByGames = createAction(
  '[Analytics] Filter Bip Stats By Games',
  props<{ gameUuids: string[]; team: ITeam }>(),
);

export const filterBipStatsByGamesSuccess = createAction(
  '[Analytics] Filter Bip Stats By Games | Success',
  props<{ analyticsBIP: IAnalyticsBIP | null }>(),
);

export const filterBipStatsByGamesFails = createAction(
  '[Analytics] Filter Bip Stats By Games | Fails',
);

export const setCurrentFilterType = createAction(
  '[Analytics] Set Current Filter Type',
  props<{ filterType: EGamesAnalyticsType | null }>(),
);

export const clearFilterValues = createAction(
  '[Analytics] Clear Filter Values',
);

export const clearAllFilterValues = createAction(
  '[Analytics] Clear All Filter Values',
);
