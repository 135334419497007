import { createReducer, on } from '@ngrx/store';
import {
  createGame,
  createGameFails,
  createGameSuccess,
  deleteGame,
  deleteGameFails,
  deleteGameSuccess,
  fillGames,
} from 'store/actions/game.actions';
import { initialGameState } from 'store/states/game.states';

export const gameReducers = createReducer(
  initialGameState,
  on(fillGames, (state, { games }) => ({ ...state, games })),
  on(createGame, (state) => ({ ...state, loading: true })),
  on(createGameSuccess, (state) => ({ ...state, loading: false })),
  on(createGameFails, (state) => ({ ...state, loading: false })),
  on(deleteGame, (state) => ({ ...state, loading: true })),
  on(deleteGameSuccess, (state) => ({ ...state, loading: false })),
  on(deleteGameFails, (state) => ({ ...state, loading: false })),
);
