import { ApolloLink } from '@apollo/client/core';

export const accessTokenMiddleware = () => {
  return new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('accessToken') ?? null;
    if (token) {
      operation.setContext({
        ...operation.getContext(),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return forward(operation);
  });
};
