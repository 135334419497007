import { createAction, props } from '@ngrx/store';
import { IMenuOptions } from 'core/interfaces/menu-options';
import {
  TFormDrawerSelection,
  TFormDrawerSpecs,
} from 'core/types/form-selection.types';

export const toggleDrawerStatus = createAction(
  '[Board] Toggle Drawer Component Open',
  props<{ status: boolean; form?: TFormDrawerSelection }>(),
);

export const setBoardTitle = createAction(
  '[Board] Set Board Title',
  props<{ title: string }>(),
);

export const setResultsToForm = createAction(
  '[Board] Set Results To Form',
  props<{ results: TFormDrawerSpecs; form: TFormDrawerSelection }>(),
);

export const clearForm = createAction(
  '[Board] Clear Form',
  props<{ form: TFormDrawerSelection }>(),
);

export const setMenuOptions = createAction(
  '[Board] Set Options',
  props<{ options: IMenuOptions[] }>(),
);
