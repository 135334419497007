import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { IAppState } from 'store/states/app.states';
import { analyticsReducers } from './analytics.reducer';
import {
  authReducers,
  invitationReducers,
  resetTokenReducers,
} from './auth.reducers';
import { boardReducers } from './board.reducers';
import { gameReducers } from './game.reducers';
import { memberReducers } from './member.reducers';
import { playerReducers } from './player.reducers';
import { seasonReducers } from './season.reducers';
import { storageMetaReducers } from './store.metareducers';
import { teamReducers } from './team.reducers';

export const appReducers: ActionReducerMap<IAppState> = {
  auth: authReducers,
  validateInvitationToken: invitationReducers,
  validateResetToken: resetTokenReducers,
  board: boardReducers,
  season: seasonReducers,
  team: teamReducers,
  analytics: analyticsReducers,
  player: playerReducers,
  member: memberReducers,
  game: gameReducers,
};

export const metaReducers: MetaReducer<IAppState | undefined>[] = [
  storageMetaReducers,
];
