import { createSelector } from '@ngrx/store';
import { getMenuOptionsByRole } from 'core/constants/menu-options';
import { EAuthRoles } from 'core/enums/roles.enums';
import { IAppState } from 'store/states/app.states';

const getAuthState = (state: IAppState) => state.auth;
const getInvitationState = (state: IAppState) => state.validateInvitationToken;

export const selectLoading = createSelector(
  getAuthState,
  (state) => state.loading,
);

export const selectUser = createSelector(getAuthState, (state) => state.user);

export const selectIsAuthenticated = createSelector(
  getAuthState,
  (state) => !!state.accessToken && !!state.refreshToken && !!state.user,
);

export const selectIsUnAuthenticated = createSelector(
  getAuthState,
  (state) => !state.accessToken || !state.refreshToken || !state.user,
);

export const selectInvitee = createSelector(
  getInvitationState,
  (state) => state.invitation,
);

export const selectMenuOptions = createSelector(getAuthState, (state) =>
  state.user ? getMenuOptionsByRole(state.user.role.roleCode) : [],
);

export const selectUserRole = createSelector(
  getAuthState,
  (state) => state.user?.role.roleCode ?? EAuthRoles.MEMBER,
);

export const selectCurrentManagedTeam = createSelector(
  getAuthState,
  (state) => state.isMasqueradeTeam,
);
