import { createReducer, on } from '@ngrx/store';
import {
  clearAllFilterValues,
  clearFilterValues,
  filterBipStatsByGames,
  filterBipStatsByGamesFails,
  filterBipStatsByGamesSuccess,
  filterOemStatsByGames,
  filterOemStatsByGamesFails,
  filterOemStatsByGamesSuccess,
  filterRispStatsByGames,
  filterRispStatsByGamesFails,
  filterRispStatsByGamesSuccess,
  getAnalyticsBIP,
  getAnalyticsBIPFails,
  getAnalyticsBIPSuccess,
  getAnalyticsOEM,
  getAnalyticsOEMFails,
  getAnalyticsOEMSuccess,
  getAnalyticsRISP,
  getAnalyticsRISPFails,
  getAnalyticsRISPSuccess,
  setCurrentFilterType,
} from '../actions/analytics.actions';
import { initialAnalyticsState } from '../states/analytics.states';

export const analyticsReducers = createReducer(
  initialAnalyticsState,
  on(getAnalyticsOEM, (state) => ({
    ...state,
    loading: true,
    analyticsOEM: null,
  })),
  on(getAnalyticsOEMSuccess, (state, { analyticsOEM }) => ({
    ...state,
    loading: false,
    analyticsOEM,
  })),
  on(getAnalyticsOEMFails, (state) => ({
    ...state,
    loading: false,
    analyticsOEM: null,
  })),
  on(getAnalyticsBIP, (state) => ({
    ...state,
    loading: true,
    analyticsOEM: null,
  })),
  on(getAnalyticsBIPSuccess, (state, { analyticsBIP }) => ({
    ...state,
    loading: false,
    analyticsBIP,
  })),
  on(getAnalyticsBIPFails, (state) => ({
    ...state,
    loading: false,
    analyticsOEM: null,
  })),
  on(getAnalyticsRISP, (state) => ({
    ...state,
    loading: true,
    analyticsRISP: null,
  })),
  on(getAnalyticsRISPSuccess, (state, { analyticsRISP }) => ({
    ...state,
    loading: false,
    analyticsRISP,
  })),
  on(getAnalyticsRISPFails, (state) => ({
    ...state,
    loading: false,
    analyticsRISP: null,
  })),
  on(filterOemStatsByGames, (state, { gameUuids }) => ({
    ...state,
    loading: true,
    gamesUuidFilterSelected: {
      ...state.gamesUuidFilterSelected,
      oem: gameUuids,
    },
    analyticsOEM: null,
  })),
  on(filterOemStatsByGamesSuccess, (state, { analyticsOEM }) => ({
    ...state,
    loading: false,
    analyticsOEM,
  })),
  on(filterOemStatsByGamesFails, (state) => ({ ...state, loading: false })),
  on(filterRispStatsByGames, (state, { gameUuids }) => ({
    ...state,
    loading: true,
    gamesUuidFilterSelected: {
      ...state.gamesUuidFilterSelected,
      risp: gameUuids,
    },
    analyticsRISP: null,
  })),
  on(filterRispStatsByGamesSuccess, (state, { analyticsRISP }) => ({
    ...state,
    loading: false,
    analyticsRISP,
  })),
  on(filterRispStatsByGamesFails, (state) => ({ ...state, loading: false })),
  on(filterBipStatsByGames, (state, { gameUuids }) => ({
    ...state,
    loading: true,
    gamesUuidFilterSelected: {
      ...state.gamesUuidFilterSelected,
      bip: gameUuids,
    },
    analyticsBIP: null,
  })),
  on(filterBipStatsByGamesSuccess, (state, { analyticsBIP }) => ({
    ...state,
    loading: false,
    analyticsBIP,
  })),
  on(filterBipStatsByGamesFails, (state) => ({ ...state, loading: false })),
  on(setCurrentFilterType, (state, { filterType }) => ({
    ...state,
    currentFilter: filterType,
  })),
  on(clearFilterValues, (state) => {
    return !!state.currentFilter
      ? {
          ...state,
          gamesUuidFilterSelected: {
            ...state.gamesUuidFilterSelected,
            [state.currentFilter]: [],
          },
        }
      : state;
  }),
  on(clearAllFilterValues, (state) => {
    return {
      ...state,
      gamesUuidFilterSelected: {
        oem: [],
        risp: [],
        bip: [],
      },
    };
  }),
);
