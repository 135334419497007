import { EAuthRoles } from 'core/enums/roles.enums';
import { IMenuOptions } from 'core/interfaces/menu-options';
import { IUser } from 'core/interfaces/user';

/**
 * ! Take a look into the routes and create/update them when is available into the module
 * TODO: Dont forget update the path in the routes and the permissions
 */

export const MENU_OPTIONS: IMenuOptions[] = [
  {
    name: 'Global',
    roles: [EAuthRoles.SUPERADMIN],
    children: [
      {
        name: 'Team Admins',
        iconName: 'sports_baseball',
        path: '/board/global/team-admins',
        roles: [EAuthRoles.SUPERADMIN],
        isDisabled: false,
      },
    ],
  },
  {
    name: 'Accounts',
    roles: [EAuthRoles.SUPERADMIN, EAuthRoles.ADMIN, EAuthRoles.MEMBER],
    children: [
      {
        name: 'Players',
        iconName: 'group',
        path: '/board/accounts/players',
        roles: [EAuthRoles.SUPERADMIN, EAuthRoles.ADMIN, EAuthRoles.MEMBER],
        isDisabled: false,
      },
      {
        name: 'Coaching Staff',
        iconName: 'military_tech',
        path: '/board/accounts/members',
        roles: [EAuthRoles.SUPERADMIN, EAuthRoles.ADMIN],
        isDisabled: false,
      },
      {
        name: 'Games',
        iconName: 'sports_cricket',
        path: '/board/accounts/games',
        roles: [EAuthRoles.SUPERADMIN, EAuthRoles.ADMIN, EAuthRoles.MEMBER],
        isDisabled: false,
      },
    ],
  },
  {
    name: 'Analytics',
    roles: [EAuthRoles.SUPERADMIN, EAuthRoles.ADMIN, EAuthRoles.MEMBER],
    children: [
      {
        name: 'OEM Offensive Efficiency Metrics',
        iconName: 'analytics',
        path: '/board/analytics/oem',
        roles: [EAuthRoles.SUPERADMIN, EAuthRoles.ADMIN, EAuthRoles.MEMBER],
        isDisabled: false,
      },
      {
        name: 'RISP-E',
        iconName: 'insert_chart_outlined',
        path: '/board/analytics/risp-e',
        roles: [EAuthRoles.SUPERADMIN, EAuthRoles.ADMIN, EAuthRoles.MEMBER],
        isDisabled: false,
      },
      {
        name: 'FBOD',
        iconName: 'show_chart',
        path: '/board/analytics/fbod',
        roles: [EAuthRoles.SUPERADMIN, EAuthRoles.ADMIN, EAuthRoles.MEMBER],
        isDisabled: true,
        isHidden: true,
      },
      {
        name: 'BIP',
        iconName: 'pie_chart',
        path: '/board/analytics/bip',
        roles: [EAuthRoles.SUPERADMIN, EAuthRoles.ADMIN, EAuthRoles.MEMBER],
        isDisabled: false,
        isHidden: false,
      },
      {
        name: 'Item STRIKE',
        iconName: 'waterfall_chart',
        path: '/board/analytics/item-strike',
        roles: [EAuthRoles.SUPERADMIN, EAuthRoles.ADMIN, EAuthRoles.MEMBER],
        isDisabled: true,
        isHidden: true,
      },
      {
        name: 'Defensive Big Inning',
        iconName: 'equalizer',
        path: '/board/analytics/defensive-big-inning',
        roles: [EAuthRoles.SUPERADMIN, EAuthRoles.ADMIN, EAuthRoles.MEMBER],
        isDisabled: true,
        isHidden: true,
      },
      {
        name: 'Offensive Big Inning',
        iconName: 'legend_toggle',
        path: '/board/analytics/offensive-big-inning',
        roles: [EAuthRoles.SUPERADMIN, EAuthRoles.ADMIN, EAuthRoles.MEMBER],
        isDisabled: true,
        isHidden: true,
      },
    ],
  },
];

export const getMenuOptionsByRole = (role: EAuthRoles): IMenuOptions[] =>
  MENU_OPTIONS.reduce((arr: IMenuOptions[], option: IMenuOptions) => {
    const isOptionValid = option.roles.includes(role);
    if (isOptionValid) {
      const optionCompiled = {
        ...option,
        children: option.children.filter(({ roles }) => roles.includes(role)),
      };
      return [...arr, optionCompiled];
    }
    return arr;
  }, []);

export const isUserRole = (role: EAuthRoles, user: IUser): boolean =>
  user.role.roleCode.includes(role);
