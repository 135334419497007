import { createReducer, on } from '@ngrx/store';
import { EAuthRoles } from 'core/enums/roles.enums';
import {
  doForgotPasswordRequest,
  doForgotPasswordRequestSuccess,
  doLogout,
  doResetPassword,
  doResetPasswordFails,
  doResetPasswordSuccess,
  doSignIn,
  doSignInFails,
  doSignInSuccess,
  doSignUp,
  doSignUpFails,
  doSignUpSuccess,
  doValidateInvitationToken,
  doValidateInvitationTokenSuccess,
  doValidateResetToken,
  doValidateResetTokenFails,
  doValidateResetTokenSuccess,
  retrieveUserInformationSuccess,
  setAccessToken,
  startManaging,
  stopManaging,
} from 'store/actions/auth.actions';
import {
  initialAuthState,
  initialValidateResetTokenState,
} from 'store/states/auth.states';
import { initialInvitationState } from '../states/invitation.states';

export const authReducers = createReducer(
  initialAuthState,
  on(setAccessToken, (state, { accessToken }) => ({
    ...state,
    accessToken,
  })),
  on(doSignIn, (state) => ({
    ...state,
    loading: true,
  })),
  on(doSignInSuccess, (state, { accessToken, refreshToken, user }) => ({
    ...state,
    loading: false,
    accessToken,
    refreshToken,
    user,
  })),
  on(doSignInFails, (state) => ({
    ...state,
    loading: false,
  })),
  on(doForgotPasswordRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(doForgotPasswordRequestSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(doLogout, (state) => ({
    ...state,
    accessToken: null,
    refreshToken: null,
    user: null,
    isMasqueradeTeam: false,
    loading: false,
  })),
  on(retrieveUserInformationSuccess, (state, { user }) => ({
    ...state,
    user,
  })),
  on(doSignUp, (state) => ({ ...state, loading: true })),
  on(doSignUpSuccess, (state) => ({ ...state, loading: false })),
  on(doSignUpFails, (state) => ({ ...state, loading: false })),
  on(doResetPassword, (state) => ({ ...state, loading: true })),
  on(doResetPasswordSuccess, (state) => ({ ...state, loading: false })),
  on(doResetPasswordFails, (state) => ({ ...state, loading: false })),
  on(startManaging, (state) => ({
    ...state,
    isMasqueradeTeam: true,
    user: {
      ...state.user!,
      role: {
        ...state.user!.role,
        name: 'ADMIN',
        roleCode: EAuthRoles.ADMIN,
      },
    },
  })),
  on(stopManaging, (state) => ({
    ...state,
    isMasqueradeTeam: false,
    user: {
      ...state.user!,
      role: {
        ...state.user!.role,
        name: 'SUPERADMIN',
        roleCode: EAuthRoles.SUPERADMIN,
      },
    },
  })),
);

export const invitationReducers = createReducer(
  initialInvitationState,
  on(doValidateInvitationToken, (state) => ({
    ...state,
    loading: false,
  })),
  on(doValidateInvitationTokenSuccess, (state, { invitation }) => ({
    ...state,
    loading: false,
    invitation,
  })),
);

export const resetTokenReducers = createReducer(
  initialValidateResetTokenState,
  on(doValidateResetToken, (state) => ({
    ...state,
    loading: false,
  })),
  on(doValidateResetTokenSuccess, (state, { status }) => ({
    ...state,
    loading: false,
    status,
  })),
  on(doValidateResetTokenFails, (state) => ({
    ...state,
    loading: false,
  })),
);
