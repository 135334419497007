import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'environments/environment';
import { appReducers, metaReducers } from 'store/reducers';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GraphErrorInterceptor } from './interceptors/graph-error.interceptor';
import { AuthenticatedStateGuard } from './guards/authenticated-state.guard';
import { UnauthenticatedStateGuard } from './guards/unauthenticated-state.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { AttachAuthTokenApiInterceptor } from './interceptors/attach-auth-token-api.interceptor';

const MATERIAL_IMPORTS = [MatSnackBarModule, MatDialogModule];

const GUARDS = [AuthenticatedStateGuard, UnauthenticatedStateGuard];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...MATERIAL_IMPORTS,
    StoreModule.forRoot(appReducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([]),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GraphErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AttachAuthTokenApiInterceptor,
      multi: true,
    },
    ...GUARDS,
  ],
})
export class CoreModule {}
