import { createAction, props } from '@ngrx/store';
import { ISeason } from 'core/interfaces/season';

export const getSeasons = createAction(
  '[Season] Get Seasons',
  props<{ name: string }>(),
);

export const getSeasonsSuccess = createAction(
  '[Season] Get seasons Success',
  props<{ seasons: ISeason[] }>(),
);

export const createSeason = createAction(
  '[Season] Create Season',
  props<{ name: string; year: number }>(),
);

export const createSeasonSuccess = createAction(
  '[Season] Create Season | Success',
  props<{ season: ISeason | null }>(),
);

export const createSeasonFail = createAction(
  '[Season] Create Season | Fail',
  props<{ errors: string[] }>(),
);

export const clearCurrentSeason = createAction('[Season] Clear Current Season');
