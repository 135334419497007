import { createAction, props } from '@ngrx/store';
import { IGame } from 'core/interfaces/game';
import { ITeam } from 'core/interfaces/team';

export const fillGames = createAction(
  '[Game] Fill Games',
  props<{
    games: IGame[];
  }>(),
);

export const createGame = createAction(
  '[Game] Create Game',
  props<{ team: ITeam; opponent: string; date: string }>(),
);

export const createGameSuccess = createAction('[Game] Create Game | Success');

export const createGameFails = createAction('[Game] Create Game | Fails');

export const deleteGame = createAction(
  '[Game] Delete Game',
  props<{ team: ITeam; game: IGame }>(),
);

export const deleteGameSuccess = createAction('[Game] Delete Game | Success');

export const deleteGameFails = createAction('[Game] Delete Game | Fails');
