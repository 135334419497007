import { IInvitation } from 'core/interfaces/invitation';
import { IUser } from 'core/interfaces/user';

export interface IMemberState {
  members: IUser[];
  invitations: IInvitation[];
  loading: boolean;
}

export const initialMemberState: IMemberState = {
  members: [],
  invitations: [],
  loading: false,
};
