import {
  clearTeamsCache,
  createTeamAsSuperAdmin,
  createTeamAsSuperAdminFails,
  createTeamAsSuperAdminSuccess,
  fillAllTeams,
  fillUserTeams,
  getAllTeams,
  getAllTeamsFails,
  getAllTeamsSuccess,
  getMyTeams,
  getMyTeamsFails,
  getMyTeamsSuccess,
  getTeam,
  getTeamFails,
  getTeamSuccess,
  setCurrentTeam,
  setCurrentTeamSeason,
  setTeamStatus,
  setTeamStatusFails,
  setTeamStatusSuccess,
} from 'store/actions/team.actions';
import { initialTeamState } from 'store/states/team.states';

import {
  createReducer,
  on,
} from '@ngrx/store';

export const teamReducers = createReducer(
  initialTeamState,
  on(getAllTeams, (state) => ({ ...state, loading: true })),
  on(getAllTeamsSuccess, (state, { teams }) => {
    const team = teams.find((team) => team.uuid === state.currentTeam?.uuid);

    if (team) {
      return {
        ...state,
        loading: false,
        teams,
        currentTeam: team,
        teamSeasons: team.seasons,
        currentTeamSeason: team.seasons[0],
      };
    } else {
      return {
        ...state,
        loading: false,
        teams,
        currentTeam: teams[0] ?? null,
        teamSeasons: teams[0]?.seasons ?? [],
        currentTeamSeason: teams[0]?.seasons[0] ?? null,
      };
    }
  }),
  on(getAllTeamsFails, (state) => ({ ...state, loading: false })),
  on(clearTeamsCache, (state) => ({
    ...state,
    currentTeam: null,
    teams: [],
    userTeams: [],
  })),
  on(getTeam, (state) => ({ ...state, loading: true })),
  on(getTeamSuccess, (state) => ({ ...state, loading: false })),
  on(getTeamFails, (state) => ({ ...state, loading: false })),
  on(setTeamStatus, (state) => ({ ...state, loading: true })),
  on(setTeamStatusSuccess, (state) => ({ ...state, loading: false })),
  on(setTeamStatusFails, (state) => ({ ...state, loading: false })),
  on(createTeamAsSuperAdmin, (state) => ({ ...state, loading: true })),
  on(createTeamAsSuperAdminSuccess, (state) => ({ ...state, loading: false })),
  on(createTeamAsSuperAdminFails, (state) => ({ ...state, loading: false })),
  on(fillUserTeams, (state, { teams }) => {
    const team = teams.find((team) => team.uuid === state.currentTeam?.uuid);

    if (team) {
      return {
        ...state,
        userTeams: teams,
        currentTeam: team,
        teamSeasons: team.seasons,
        currentTeamSeason: team.seasons[0],
      };
    } else {
      return {
        ...state,
        userTeams: teams,
        currentTeam: teams[0] ?? null,
        teamSeasons: teams[0]?.seasons ?? [],
        currentTeamSeason: teams[0]?.seasons[0] ?? null,
      };
    }
  }),
  on(fillAllTeams, (state, { teams }) => ({ ...state, teams })),
  on(setCurrentTeam, (state, { team }) => ({
    ...state,
    currentTeam: team,
    teamSeasons: team?.seasons ?? [],
    currentTeamSeason: team?.seasons[0] ?? null,
  })),
  on(setCurrentTeamSeason, (state, { season }) => ({
    ...state,
    currentTeamSeason: season,
  })),
  on(getMyTeams, (state) => ({ ...state, loading: true })),
  on(getMyTeamsSuccess, (state, { teams }) => ({
    ...state,
    loading: false,
    userTeams: teams,
  })),
  on(getMyTeamsFails, (state) => ({ ...state, loading: false })),
);
