import { state } from '@angular/animations';
import { createReducer, on } from '@ngrx/store';
import {
  clearCurrentSeason,
  createSeason,
  createSeasonFail,
  createSeasonSuccess,
  getSeasonsSuccess,
} from 'store/actions/season.actions';
import { initialSeasonState } from 'store/states/season.states';
import {
  createUploadGame,
  createUploadGameFails,
  createUploadGameSuccess,
  createUploadSeason,
  createUploadSeasonFails,
  createUploadSeasonSuccess,
} from '../actions/upload-season.actions';

export const seasonReducers = createReducer(
  initialSeasonState,
  on(getSeasonsSuccess, (state, { seasons }) => ({ ...state, seasons })),
  on(createSeason, (state) => ({
    ...state,
    currentSeason: null,
    loading: true,
  })),
  on(createSeasonSuccess, (state, { season }) => ({
    ...state,
    currentSeason: season,
    loading: false,
  })),
  on(createSeasonFail, (state) => ({ ...state, loading: false })),
  on(clearCurrentSeason, (state) => ({ ...state, currentSeason: null })),
  on(createUploadSeason, (state, {}) => ({
    ...state,
    loading: true,
  })),
  on(createUploadSeasonSuccess, (state, { uploadedSeason }) => ({
    ...state,
    loading: false,
    uploadedSeason,
  })),
  on(createUploadSeasonFails, (state, {}) => ({
    ...state,
    loading: false,
  })),
  on(createUploadGame, (state) => ({ ...state, loading: true })),
  on(createUploadGameSuccess, (state) => ({ ...state, loading: false })),
  on(createUploadGameFails, (state) => ({ ...state, loading: false })),
);
