import { IUser } from 'core/interfaces/user';

export interface IAuthState {
  accessToken: string | null;
  refreshToken: string | null;
  user: IUser | null;
  loading: boolean;
  isMasqueradeTeam: boolean;
}

export const initialAuthState: IAuthState = {
  accessToken: null,
  refreshToken: null,
  user: null,
  loading: false,
  isMasqueradeTeam: false,
};

export interface IValidateResetTokenState {
  loading: boolean;
  status: boolean;
}

export const initialValidateResetTokenState: IValidateResetTokenState = {
  loading: false,
  status: false,
};
