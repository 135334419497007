<ng-container>
  <div class="alert" [ngClass]="state">
    <mat-icon
      *ngIf="showIcon"
      class="alert__icon"
      [ngStyle]="{ 'font-size': iconSize | stringToPx }"
      >{{ iconState }}</mat-icon
    >
    <div class="alert__info">
      <div class="alert__info__title" *ngIf="title">{{ title }}</div>
      <div class="alert__info__description" *ngIf="description">
        {{ description }}
      </div>

      <div
        class="alert__info__actions"
        *ngIf="primaryAction || secondaryAction"
      >
        <button
          mat-flat-button
          [color]="state"
          class="button-surface"
          *ngIf="primaryAction"
        >
          {{ primaryAction }}
        </button>

        <button
          mat-flat-button
          [color]="state + '-secondary'"
          class="button-surface"
          *ngIf="secondaryAction"
        >
          {{ secondaryAction }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
