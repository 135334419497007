import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MENU_OPTIONS } from 'core/constants/menu-options';
import { environment } from 'environments/environment';
import { filter } from 'rxjs/operators';
import { setBoardTitle } from 'store/actions/board.actions';
import { IAppState } from 'store/states/app.states';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private siteName = environment.SITE_NAME;

  constructor(
    private router: Router,
    private store: Store<IAppState>,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const paths = MENU_OPTIONS.map((item) => item.children).flat();
        const path = paths.find((item) =>
          item.path.includes((event as NavigationEnd).url),
        );
        if (path) {
          const title = path.name;
          this.store.dispatch(setBoardTitle({ title }));
          this.title.setTitle(`${this.siteName} | ${title}`);
        }
      });
  }
}
