import { createReducer, on } from '@ngrx/store';
import {
  clearForm,
  setBoardTitle,
  setMenuOptions,
  setResultsToForm,
  toggleDrawerStatus,
} from 'store/actions/board.actions';
import { initialBoardState } from 'store/states/board.states';

export const boardReducers = createReducer(
  initialBoardState,
  on(toggleDrawerStatus, (state, { status, form }) => ({
    ...state,
    isDrawerOpen: status,
    drawerForm: form ?? 'member',
  })),
  on(setBoardTitle, (state, { title }) => ({ ...state, title })),
  on(setResultsToForm, (state, { form, results }) => ({
    ...state,
    drawerFormResults: {
      ...state.drawerFormResults,
      [form]: results,
    },
  })),
  on(clearForm, (state, { form }) => ({
    ...state,
    drawerFormResults: { ...state.drawerFormResults, [form]: null },
  })),
  on(setMenuOptions, (state, { options }) => ({
    ...state,
    menuOptions: options,
  })),
);
