import { IInvitation } from 'core/interfaces/invitation';

export interface IInvitationState {
  loading: boolean;
  status: boolean;
  invitation: Partial<IInvitation> | null;
}

export const initialInvitationState: IInvitationState = {
  loading: false,
  status: false,
  invitation: null,
};
