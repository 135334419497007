import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { IOpenSnackBarConfig } from 'core/interfaces/snackbar-custom';
import { TAlertStates } from '../../../core/types/alert.types';

@Component({
  selector: 'ta-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() state: TAlertStates = 'success';
  @Input() iconState: string = '';
  @Input() title!: string;
  @Input() description!: string;
  @Input() showIcon: boolean = false;
  @Input() primaryAction!: string;
  @Input() secondaryAction!: string;
  iconSize: number = 14;

  constructor(@Inject(MAT_SNACK_BAR_DATA) private data: IOpenSnackBarConfig) {
    Object.assign(this, this.data);
  }

  ngOnInit(): void {
    switch (this.state) {
      case 'success':
        this.iconState = 'check_circle';
        break;

      case 'warning':
        this.iconState = 'warning';
        break;

      case 'critical':
        this.iconState = 'error';
        break;

      default:
        this.iconState = '';
        break;
    }
  }
}
