import { EGamesAnalyticsType } from 'core/enums/games-file-types.enums';
import { IAnalyticsBIP } from 'core/interfaces/analytics-bip';
import { IAnalyticsOEM } from 'core/interfaces/analytics-oem';
import { IAnalyticsRISP } from 'core/interfaces/analytics-risp';

export interface IAnalyticsState {
  loading: boolean;
  analyticsOEM: IAnalyticsOEM | null;
  analyticsRISP: IAnalyticsRISP | null;
  analyticsBIP: IAnalyticsBIP | null;
  gamesUuidFilterSelected: {
    oem: string[];
    risp: string[];
    bip: string[];
  };
  currentFilter: EGamesAnalyticsType | null;
}

export const initialAnalyticsState: IAnalyticsState = {
  loading: false,
  analyticsOEM: null,
  analyticsRISP: null,
  analyticsBIP: null,
  currentFilter: null,
  gamesUuidFilterSelected: {
    oem: [],
    risp: [],
    bip: [],
  },
};
