import { ISeason } from 'core/interfaces/season';
import { ITeam } from 'core/interfaces/team';
import { IUser } from 'core/interfaces/user';

export interface ITeamState {
  teams: ITeam[];
  admins: IUser[];
  loading: boolean;
  userTeams: ITeam[];
  currentTeam: ITeam | null;
  teamSeasons: ISeason[];
  currentTeamSeason: ISeason | null;
}

export const initialTeamState: ITeamState = {
  teams: [],
  admins: [],
  loading: false,
  userTeams: [],
  currentTeam: null,
  teamSeasons: [],
  currentTeamSeason: null,
};
