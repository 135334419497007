import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { UtilService } from 'core/services/util.service';

@Injectable()
export class GraphErrorInterceptor implements HttpInterceptor {
  constructor(private utilService: UtilService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpResponse<unknown> | HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((response) => this.handleGraphQLResponse(response)),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
    );
  }

  private handleGraphQLResponse(event: HttpEvent<unknown>): HttpEvent<unknown> {
    // ? For a technical solution a put the following cast 'cause I need to access the graph response
    // ? After that I don't need to do anything more, so I just return the same request in the map operator
    const response = event as HttpResponse<unknown>;
    const body = response?.body as FetchResult<unknown>;

    if (body?.errors?.length) {
      body.errors.forEach(({ message, extensions }) => {
        this.launchSnackBar(message);
        this.handleUnathorizedError(extensions);
      });
    }

    return event;
  }

  private handleUnathorizedError(
    extension: Record<string, Record<string, unknown>>,
  ): void {
    const current = extension['response'];

    if (current?.['statusCode'] === 401) {
      const message = current?.['message'] ?? null;
      // ! We need to create a flow to handle the error
      // ! Pending a formal implementation of refreshToken
      this.launchSnackBar(
        (message as string | null) ||
          'Your session has expired. Please log-in again.',
      );
      this.handleLogout();
    }
  }

  private handleLogout(): void {
    this.utilService.doLogout();
  }

  private handleError(
    error: HttpErrorResponse,
  ): Observable<HttpEvent<unknown>> {
    this.launchSnackBar(error.message);
    return throwError(error);
  }

  private launchSnackBar(message: string): void {
    this.utilService.openSnackBar({
      description: message,
      state: 'critical',
    });
  }
}
