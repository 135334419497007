import { createAction, props } from '@ngrx/store';
import { IUser } from 'core/interfaces/user';
import { IInvitation } from 'core/interfaces/invitation';
import { ITeam } from 'core/interfaces/team';

export const setAccessToken = createAction(
  '[Auth] Set access token',
  props<{ accessToken: string }>(),
);

export const doSignIn = createAction(
  '[Auth] Sign in',
  props<{ email: string; password: string }>(),
);

export const doSignInSuccess = createAction(
  '[Auth] Sign in | Success',
  props<{
    accessToken: string | null;
    refreshToken: string | null;
    user: IUser | null;
  }>(),
);

export const doSignInFails = createAction(
  '[Auth] Sign in | Fails',
  props<{ errors: string[] }>(),
);

export const doForgotPasswordRequest = createAction(
  '[Auth] Forgot Password Request',
  props<{ email: string }>(),
);

export const doForgotPasswordRequestSuccess = createAction(
  '[Auth] Forgot Password Request | Success',
);

export const doLogout = createAction('[Auth] Logout');

export const doSignUp = createAction(
  '[Auth] Create Account',
  props<{ token: string; password: string }>(),
);

export const doSignUpSuccess = createAction('[Auth] Create Account | Success');

export const doSignUpFails = createAction(
  '[Auth] Create account | Fails',
  props<{ errors: string[] }>(),
);

export const doValidateInvitationToken = createAction(
  '[Auth] Validate invitation token',
  props<{ token: string }>(),
);

export const doValidateInvitationTokenSuccess = createAction(
  '[Auth] Validate invitation token | Success',
  props<{
    invitation: Partial<IInvitation>;
  }>(),
);

export const doValidateInvitationTokenFails = createAction(
  '[Auth] Validate invitation token | Fails',
  props<{ errors: string[] }>(),
);

export const retrieveUserInformation = createAction(
  '[Auth] Retrieve User Information',
);

export const retrieveUserInformationSuccess = createAction(
  '[Auth] Retrieve User Information | Success',
  props<{ user: IUser | null }>(),
);

export const retrieveUserInformationFails = createAction(
  '[Auth] Retrieve User Information | Fails',
  props<{ errors: string[] }>(),
);

export const doResetPassword = createAction(
  '[Auth] Reset Password',
  props<{ password: string; resetToken: string }>(),
);

export const doResetPasswordSuccess = createAction(
  '[Auth] Reset Password | Success',
);

export const doResetPasswordFails = createAction(
  '[Auth] Reset Password | Fails',
);

export const doValidateResetToken = createAction(
  '[Auth] Validate reset token',
  props<{ token: string }>(),
);

export const doValidateResetTokenSuccess = createAction(
  '[Auth] Validate reset token | Success',
  props<{
    status: boolean;
  }>(),
);

export const doValidateResetTokenFails = createAction(
  '[Auth] Validate reset token | Fails',
  props<{ errors: string[] }>(),
);

export const startManaging = createAction('[Auth] Start Managing');

export const stopManaging = createAction('[Auth] Stop Managing');
