import { createAction, props } from '@ngrx/store';
import { IUploadGameForm } from 'core/interfaces/form-definitions/upload-game-form';
import { IRESTUploadGameResponse } from 'core/interfaces/responses/upload-game-response';
import { IRESTUploadSeasonResponse } from 'core/interfaces/responses/upload-season-response';

export const createUploadSeason = createAction(
  '[Upload] Create upload season',
  props<{ teamUUID: string; seasonUUID: string; file: FormData }>(),
);

export const createUploadSeasonSuccess = createAction(
  '[Upload] Create upload season | success',
  props<{ uploadedSeason: IRESTUploadSeasonResponse }>(),
);

export const createUploadSeasonFails = createAction(
  '[Upload] Create upload season | Fails',
  props<{ errors: string[] }>(),
);

export const createUploadGame = createAction(
  '[Upload] Create Upload Game',
  props<{ values: IUploadGameForm }>(),
);

export const createUploadGameSuccess = createAction(
  '[Upload] Create Upload Game | Success',
  props<{ uploadedGame: IRESTUploadGameResponse }>(),
);

export const createUploadGameFails = createAction(
  '[Upload] Create Upload Game | Fails',
  props<{ errors: string[] }>(),
);
