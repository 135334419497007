import { createReducer, on } from '@ngrx/store';
import {
  cleanMembers,
  createMember,
  createMemberFails,
  createMemberSuccess,
  fillInvitations,
  fillMembers,
} from 'store/actions/member.actions';
import { initialMemberState } from 'store/states/member.states';

export const memberReducers = createReducer(
  initialMemberState,
  on(createMember, (state) => ({ ...state, loading: true })),
  on(createMemberSuccess, (state) => ({ ...state, loading: false })),
  on(createMemberFails, (state) => ({ ...state, loading: false })),
  on(fillMembers, (state, { members }) => ({ ...state, members })),
  on(fillInvitations, (state, { invitations }) => ({ ...state, invitations })),
  on(cleanMembers, (state) => ({
    ...state,
    members: [],
    invitations: [],
    loading: false,
  })),
);
