// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BACKEND: {
    // GRAPHQL_BOARD: 'http://localhost:3000/graphql',
    // REST_UPLOAD: 'http://localhost:3000/api',
    // GRAPHQL_BOARD: 'https://team-analytics-staging.herokuapp.com/graphql',
    // REST_UPLOAD: 'https://team-analytics-staging.herokuapp.com/api',
    GRAPHQL_BOARD: 'https://www.team-analytics.org/graphql',
    REST_UPLOAD: 'https://www.team-analytics.org/api',
  },
  SITE_NAME: 'TEAM Analytics',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
