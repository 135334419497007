import { ISeason } from 'core/interfaces/season';
import { ITeam } from 'core/interfaces/team';
import { IUser } from 'core/interfaces/user';

import {
  createAction,
  props,
} from '@ngrx/store';

export const createTeamAsSuperAdmin = createAction(
  '[Team] Create Team As Super Admin',
  props<{
    name: string;
    seasonName: string;
    seasonYear: number;
    adminEmail: string;
    adminName: string;
  }>(),
);

export const createTeamAsSuperAdminSuccess = createAction(
  '[Team] Create Team As Super Admin | Success',
);

export const createTeamAsSuperAdminFails = createAction(
  '[Team] Create Team As Super Admin | Fails',
);

export const getTeamAdmins = createAction('[Team] Get Team Admins');

export const getTeamAdminsSuccess = createAction(
  '[Team] Get Team Admins | Success',
  props<{ admins: IUser[] }>(),
);

export const getAllTeams = createAction('[Team] Get All Teams');

export const getAllTeamsSuccess = createAction(
  '[Team] Get All Teams | Success',
  props<{ teams: ITeam[] }>(),
);

export const getAllTeamsFails = createAction(
  '[Team] Get All Teams | Fails',
  props<{ errors: string[] }>(),
);

export const fillUserTeams = createAction(
  '[Team] Fill User Teams',
  props<{ teams: ITeam[] }>(),
);

export const setCurrentTeam = createAction(
  '[Team] Set Current Team',
  props<{ team: ITeam | null }>(),
);

export const clearTeamsCache = createAction('[Team] Clear Team Cache');

export const getTeam = createAction(
  '[Team] Get Team',
  props<{ teamUuid: string; seasonUuid: string }>(),
);

export const getTeamSuccess = createAction(
  '[Team] Get Team | Success',
  props<{ team: ITeam | null }>(),
);

export const getTeamFails = createAction('[Team] Get Team | Fails');

export const setTeamStatus = createAction(
  '[Team] Set Team Status',
  props<{ status: boolean; teamUuid: string }>(),
);

export const setTeamStatusSuccess = createAction(
  '[Team] Set Team Status | Success',
);

export const setTeamStatusFails = createAction(
  '[Team] Set Team Status | Fails',
);

export const deleteTeam = createAction(
  '[Team] Delete Team',
  props<{ team: ITeam }>(),
);

export const deleteTeamSuccess = createAction('[Team] Delete Team | Success');

export const deleteTeamFails = createAction('[Team] Delete Team | Fails');

export const fillAllTeams = createAction(
  '[Team] Fill All Teams',
  props<{ teams: ITeam[] }>(),
);

export const setCurrentTeamSeason = createAction(
  '[Season] Set Current Team Season',
  props<{ season: ISeason | null }>(),
);

export const createTeam = createAction(
  '[Team] Create Team',
  props<{ name: string; season: { name: string; year: number } }>(),
);

export const createTeamSuccess = createAction('[Team] Create Team | Success');

export const createTeamFails = createAction('[Team] Create Team | Fails');

export const createTeamSeason = createAction(
  '[Season] Create Team Season',
  props<{ teamUuid: string; formData: { name: string; year: number } }>(),
);

export const createTeamSeasonSuccess = createAction(
  '[Season] Create Team Season | Success',
);

export const createTeamSeasonFails = createAction(
  '[Season] Create Team Season | Fails',
);

export const getMyTeams = createAction('[Team] Get My Teams');

export const getMyTeamsSuccess = createAction(
  '[Team] Get My Teams | Success',
  props<{ teams: ITeam[] }>(),
);

export const getMyTeamsFails = createAction(
  '[Team] Get My Teams | Fails',
  props<{ errors: string[] }>(),
);
