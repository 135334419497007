import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { selectIsUnAuthenticated } from 'store/selectors/auth.selectors';
import { IAppState } from 'store/states/app.states';

@Injectable()
export class UnauthenticatedStateGuard implements CanActivate {
  constructor(private store: Store<IAppState>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store.select(selectIsUnAuthenticated).pipe(
      tap((value) => {
        if (!value) {
          this.router.navigate(['/board']);
        }
      }),
    );
  }
}
